import { Match, Show, Switch, createEffect, createRenderEffect, onMount, splitProps, untrack } from "solid-js";
import { Atom, Ctrl, log, state } from ":mods";
import { ShowMaterial } from "../../show-material";
import { MaterialFilter } from "./material-filter";
import { unwrap } from "solid-js/store";
import { ASubmoduleMaterial, ShowSubmissionStudentPreviewViewProps } from "../../../models";
import { getSubmissionsStudent } from "../../../apis";
// import { Topic } from "../../show-material/task/topics";
import { ShowMaterialQuestion } from "../../show-material/question";
import { ShowMaterialTask } from "../../show-material/task";
export function StudentSubmodulePreview(props: ShowSubmissionStudentPreviewViewProps) {
  const [local, others] = splitProps(props, ["$", "applicationID", "type", "id"]);
  // const params = local.$.hooks.useParams();
  // const submoduleID = params.id ?? local.submoduleID;
  const $details = state.create(undefined);
  // const $details = state.createAsync([, null], { manualFetch: true });
  // const $materials = state.create(undefined as ASubmoduleMaterial[]);

  local.$.actions.clearLayout();
  local.$.actions.updateLayout({
    title: () => (
      <div class="flex flex-col ">
        <h3>{$details.value?.data?.name ?? ""}</h3>
        <h1>{$details.value?.data?.description ?? ""}</h1>
      </div>
    ),
    titleBorder: true,
  });
  onMount(() => {
    // FIXME: passing multi argument to state async causes stale state
    getSubmissionsStudent(String(local.applicationID), local.type === "task" ? "task" : "question", local.id).then(
      (d) => {
        console.log("d :: ", d);
        $details.set(d);
      }
    );
  });
  return (
    <Show
      when={!!$details.value}
      fallback={
        <div class="flex w-full h-full items-center justify-center">
          <Atom.Loader.Platform
            title="Museum Taining Material"
            msg="Loading content..."
            class="relative flex$col extend$ fight$"
          />
        </div>
      }
    >
      <Switch fallback={<div>unknown material content type</div>}>
        <Match when={local.type === "question"}>
          <ShowMaterialQuestion
            $={local.$ as any}
            elements={{
              data: $details.unwrap.data,
            }}
            options={{
              startShow: true,
            }}
          />
        </Match>
        <Match when={local.type === "task"}>
          <ShowMaterialTask
            $={local.$}
            elements={{
              data: $details.unwrap.data,
            }}
            options={{
              showState: "show_only",
            }}
          />
        </Match>
      </Switch>
    </Show>
  );
}
